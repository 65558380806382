import React from 'react';
import Layout from '../components/layout';
import { alternateLinks } from '../data/site-data';
import Hero from '../components/common/hero';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'Иммиграция в США | Иммиграционный Aдвокат США | Pandev Law',
  description:
    'Лицензирован иммиграционный адвокат в США, Нью-Йорк и Южная Каролина, оказывает помощь по иммиграции в США. Пожалуйста, свяжитесь с нами. ',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
  alternateLinks,
};

const schema = `{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Иммиграция в США",
  "image": "https://www.pandevlaw.com/img/statue-liberty.jpg",
  "description": "Лицензирован иммиграционный адвокат в США, Нью-Йорк и Южная Каролина, оказывает помощь по иммиграции в США. Пожалуйста, свяжитесь с нами.",
  "brand": "Pandev Law",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  }
}`;

function Russian({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema} lang="ru-RU">
      <Hero
        title={<strong>Ваше Путешествие в Америку Начинается Здесь</strong>}
        isTitleBgTransparent
        hideContactLink>
        <div className="text-bg-white">
          <p className="text-lead mb-5 mb-md-6">
            <strong>Pandev Law</strong> - юридическая фирма по вопросам иммиграции и
            бизнеса c исключительно высоким качеством представительства. Наш Девиз «Ваше
            путешествие в Америку начинается здесь» говорит сам за себя.
            <br />
            <br />Я предоставляю иммиграционные решения для инвесторов, международных
            компаний, малых предприятий и частных лиц которые хотят переехать в
            Соединенные Штаты. Я располагаю уникальными возможностями эффективно
            представлять индивидуальных и корпоративных клиентов на всей территории
            Соединенных Штатов и во всем мире.
          </p>
        </div>
      </Hero>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <p className="text-body">
              Моя иммиграционная практика сосредоточена на иммиграции инвесторов и на
              основаниы трудоустройства, в частности, EB-5 и E-2 инвесторов, специалистов
              H-1B, и получателей L-1 внутрифирменных трансферов.
              <br />
              <br />Я также помогаю объединять семьи посредством брака и подаю другие
              семейные петиции. Я имею большой опыт работы с процессом создания зеленой
              карты на основе трудоустройства, включая трудовые сертификаты PERM, петиции
              национальных интересов EB-2 и петиции EB-1 для многонациональных менеджеров
              и лиц с исключительными способностями, включая ученых, исследователей,
              художников и спортсменов.
              <br />
              <br />
              Моя бизнес практика сосредоточена на консультировании предпринимателей
              малого и среднего бизнеса по вопросам формирования и управления, слияния
              бизнесов, коммерческих сделок и финансирования, включая венчурные капиталы.
              Я регулярно консультирую по международным сделкам и представляю иностранные
              компании, стремящиеся выйти на рынок США. Я консультирую по вопросам
              соблюдения требований в отношении импорта/экспорта и помогаю получить помощь
              в случае предполагаемых нарушений таможенного законодательства.
            </p>
          </div>
          <div className="col-lg-6">
            <p className="text-body">
              До формирования Pandev Law я работал в качестве судебного адвоката в
              Министерстве юстиции США, в Офисе судебного разбирательства по
              иммиграционным делам в Вашингтоне. Как адвокат по судебным делам, я
              представлял государственные органы, включая Государственный департамент США,
              Службу таможенного и пограничного контроля США и Службу гражданства и
              иммиграции США, в судебных разбирательствах в судах федеральных округов и
              федеральных апелляционных судах на всей территории Соединенных Штатов. Ранее
              я занимал должности в Министерстве труда США, Иммиграционном суде Атланты,
              Нью-йоркском офисе крупной международной юридической фирмы, иммиграционной
              фирме в Вашингтоне, и в крупнейшей в мире иммиграционной фирме.
              <br />
              <br />Я жил в Москве и Санкт-Петербурге и специализировался в русском языке
              в университете. Я помогаю многочисленным клиентам из русскоговорящих стран.
              Я с болшим удоволствием консультировал бы вас по-русски.
              <br />
              <br />
              Если вы хотите связаться с нами, вы можете написать нам по адресу{' '}
              <a className="link-primary" href="mailto:admin@pandevlaw.com">
                admin@pandevlaw.com
              </a>
              или воспользоваться нашей{' '}
              <a className="link-primary" href="/contact/">
                контактной формой
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <CtaDeck
        title="Запишитесь на консультацию у юриста сегодня"
        buttonText="Поговорите с юристом"
      />
    </Layout>
  );
}

export default Russian;
